import styled from '@emotion/styled';

import { colors } from '@/styles/constant';

export const SignupWrap = styled.div`
  display: flex;
  min-height: 600px;
  min-width: 360px;

  @media (max-width: 760px) {
    > div:first-of-type {
      display: none;
    }
  }
`;

export const SignupContentWrap = styled.div`
  display: flex;
  flex: 480px;
  flex-direction: column;
  justify-content: center;
  margin: 60px auto;
  padding: 0 20px;
  max-width: 520px;
  min-height: calc(100vh - 120px);

  > h2 {
    color: ${colors.NAVY_NEW};
    font-size: 28px;
    font-weight: bold;
    line-height: 1.43;
  }

  > p {
    color: #646b79;
    font-size: 16px;
    line-height: 1.4;
    margin: 12px 0 0;

    > a {
      color: ${colors.BROWN_NEW};
      font-size: 16px;
      font-weight: bold;
      line-height: 1.4;
      text-decoration: none;
    }
  }

  > div {
    margin-top: 40px;
  }

  > div + div {
    margin-top: 32px;
  }
`;

export const SideContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 18px 40px 20px;
  width: 400px;
`;

export const DoubleFormGroup = styled.div<{ line?: boolean }>`
  display: flex;
  ${({ line }) => line && 'padding-top:32px; border-top: 1px solid #e1e1e1'};

  > * {
    flex: 1;
  }

  > * + * {
    flex: 1;
    margin-left: 12px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  > button + button {
    margin-left: 18px;
  }
`;

export const FormTitleStyle = styled.div`
  color: #646b79;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  padding-bottom: 4px;
`;
export const FormContentStyle = styled.div`
  color: ${colors.NAVY_NEW};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 8px;

  > ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: -7px;

    > li {
      align-items: center;
      background-color: rgba(255, 255, 255, 50%);
      border: solid 1px #e1e1e1;
      border-radius: 23px;
      display: flex;
      height: 30px;
      justify-content: center;
      margin: 7px 8px 0 0;
      padding: 0 10px;
    }
  }
`;

export const AccountInfoTextBoxWrap = styled.div`
  align-items: center;
  background-color: #f5f6f7;
  border-radius: 8px;
  color: #646b79;
  display: flex;
  font-size: 12px;
  font-weight: normal;
  justify-content: center;
  line-height: 1.67;
  margin: 32px 0 0;
  min-height: 54px;

  > b {
    display: inline;
  }

  > span {
    margin-right: 4px !important;
  }

  > ul {
    display: flex;
  }
`;
